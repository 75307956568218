/* eslint-disable max-len */

import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import { fontFamily, fontSize, FontSizes, fontWeight, FontWeights, minWidth } from '@calm-web/design-system';

export const Wrapper = styled.nav`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 40px;

	ul {
		padding: 0;
		display: none;
		${minWidth('desktop')} {
			display: flex;
		}
		flex-wrap: wrap;

		li {
			list-style: none;
			margin-bottom: 8px;
			margin-right: 8px;
			&:last-of-type {
				margin-right: 0;
			}
		}
	}
`;

export const NavItem = styled.a<{ $isActive?: boolean }>`
	background-color: ${p => (p.$isActive ? palette('white') : palette('transparentBlackBackground'))};
	border-radius: ${theme('buttons.borderRadius')};
	color: ${p => (p.$isActive ? palette('midnightBlue') : palette('white'))};
	display: block;
	${fontSize(FontSizes.base)};
	${fontWeight(FontWeights.Regular)};
	padding: 2px 16px;
	text-decoration: none;
`;

export const NavItemsSelect = styled.select`
	align-items: center;
	appearance: none;
	background: ${palette('transparentBlackBackground')}
		/* This is pulled from www-app - it's a down arrow icon */
		url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='18' height='18' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>")
		no-repeat;
	background-position: right 24px top 22px;
	border-radius: 8px;
	border: none;
	color: ${palette('white')};
	cursor: pointer;
	display: flex;
	${fontFamily};
	${fontSize(FontSizes.base)};
	height: 56px;
	padding: 8px 16px;
	width: 100%;

	${minWidth('desktop')} {
		display: none;
	}
`;
